/* CONTAINER */
/* GRID */
/* BREAKPOINTS */
/* TYPOGRAPHY */
/* COLORS - TODO: To be deprecated */
/* COLORS V2 */
/* PRIMARY BRAND COLORS */
/* NEUTRAL COLORS */
/* LINK COLORS */
/* FOCUS COLORS */
/* STATUS COLORS */
/* OVERLAY COLORS */
/* ANIMATIONS */
/* ELEVATIONS */
/* VALUES */
/* SIDEBAR */
/* SIDE NAVIGATION */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main,
picture {
  display: block;
}

html {
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  background: #ffffff;
  color: #092d43;
}

select {
  letter-spacing: 0.1px;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

input,
textarea,
select,
button {
  font: inherit;
}

::selection {
  background-color: #6ba09b;
  color: #ffffff;
}

html:not([data-whatintent=keyboard]) *:focus {
  outline: none;
}

body {
  overflow-y: scroll;
}

body.is-scroll-disabled #page {
  overflow-y: hidden;
  position: fixed;
  width: 100%;
}

.h-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
@media only screen and (min-width: 800px) {
  .h-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.h-clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.h-pull-left {
  float: left;
}

.h-pull-right {
  float: right;
}

.h-img-fluid {
  max-width: 100%;
  height: auto;
}

.h-fluid {
  max-width: 100%;
  height: auto;
}

.h-hidden {
  display: none !important;
}

.h-visible {
  display: block !important;
}

@media only screen and (max-width: 599px) {
  .h-hidden-xs {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 799px) {
  .h-hidden-sm {
    display: none !important;
  }
}

@media only screen and (min-width: 800px) and (max-width: 999px) {
  .h-hidden-md {
    display: none !important;
  }
}

@media only screen and (min-width: 1000px) {
  .h-hidden-lg {
    display: none !important;
  }
}

img.lazyload,
img.lazyloading {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

img.lazyloaded {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

/* Hides content visually */
.h-hidden-visually {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.h-text-center {
  text-align: center;
}

.h-text-right {
  text-align: right;
}

.text > * + * {
  margin-top: 1.5em;
}
.text > .text--margin-double {
  margin-top: 3em;
}
.text > :first-child {
  margin-top: 0;
}
.text > :last-child {
  margin-bottom: 0;
}
.text > h1,
.text > .h1 {
  margin-top: 16px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 600px) {
  .text > h1,
  .text > .h1 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

h1:not([class]),
.h1 {
  font-size: 35px;
  font-weight: 500;
  line-height: 40px;
}

h2:not([class]),
.h2 {
  font-size: 29px;
  font-weight: 500;
  line-height: 32px;
}

h3:not([class]),
.h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}

h4:not([class]),
.h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

h5:not([class]),
.h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

h6:not([class]),
.h6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

small,
.text-small {
  font-size: 12px;
  line-height: 16px;
}

.text-medium {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

.text-semi-bold {
  font-weight: 600;
}

i,
em,
.italic {
  font-style: italic;
}

b,
strong,
.bold {
  font-weight: 700;
}

a:not([class]),
.link {
  color: #2b3bcf;
  text-decoration: none;
  cursor: pointer;
}
a:not([class]):hover,
.link:hover {
  color: #1e2991;
  text-decoration: underline;
}
a:not([class]):active,
.link:active {
  color: #1a247e;
  text-decoration: underline;
}
html[data-whatintent=keyboard] a:not([class]):focus,
html[data-whatintent=keyboard] .link:focus {
  outline-offset: 1px;
  outline: 2px solid #3f00ff;
}

ul.list,
ul:not([class]) {
  list-style: square;
  padding-left: 20px;
}

ol.list,
ol:not([class]) {
  list-style: decimal;
  padding-left: 20px;
}

.textfield {
  position: relative;
  width: 100%;
  max-width: 100%;
}

.textfield__inner {
  display: block;
  position: relative;
}

.textfield__label {
  display: block;
  margin-bottom: 4px;
  color: #355365;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.textfield--hidden-label .textfield__label {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.textfield__input {
  display: block;
  margin: 0;
  padding: 8px;
  width: 100%;
  background: #ffffff;
  border: 2px solid #dfe4e7;
  border-radius: 3px;
  outline: none;
  color: #092d43;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-appearance: none;
  white-space: nowrap;
  transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out;
}
.textfield__input[type=date] {
  line-height: 20px;
  height: 40px;
}
.textfield__input::placeholder {
  opacity: 1;
  color: #5d7483;
}
.textfield.is-disabled .textfield__input {
  background-color: #f5f7f7;
  border-color: #ebeef0;
  color: #899aa5;
  cursor: not-allowed;
}
.textfield.is-invalid .textfield__input {
  border-color: #de350b;
}
.textfield__input::-ms-clear {
  display: none;
}
.textfield__input:hover {
  background-color: #ebeef0;
  border-color: #c2cbd0;
}
html[data-whatintent=keyboard] .textfield__input:focus, .textfield.is-focused .textfield__input, .textfield__input:focus {
  border-color: #2b786f;
}
.textfield--with-icon .textfield__input {
  padding-right: 36px;
}

.textfield__error {
  margin-top: 4px;
  color: #de350b;
  font-size: 12px;
  line-height: 16px;
}

.textfield__note {
  margin-top: 4px;
  color: #7a8e99;
  font-size: 12px;
  line-height: 16px;
}

.textfield__icon {
  position: absolute;
  top: 8px;
  right: 8px;
  flex: 0 0 24px;
  font-size: 24px;
  pointer-events: none;
}

.icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.accordion-item {
  background: rgba(220, 226, 235, 0.5);
  border-radius: 8px;
}

.accordion-item__content {
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms ease-in-out, height 150ms ease-in-out;
}
.accordion-item.is-open .accordion-item__content {
  opacity: 1;
  visibility: visible;
}

.accordion-item__inner {
  padding: 4px 12px 20px;
}

.accordion-item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 12px;
  width: 100%;
  background: none;
  border: none;
  color: #0056b3;
  cursor: pointer;
}

.accordion-item__icon {
  flex: 0 0 24px;
  font-size: 24px;
  margin-left: 12px;
  transform: rotate(180deg);
  transition: transform 150ms ease-in-out;
}
.accordion-item.is-open .accordion-item__icon {
  transform: rotate(0deg);
}

.accordion-item__title {
  display: flex;
  align-items: center;
  gap: 4px;
  transition: opacity 150ms ease-in-out;
}
.accordion-item__header:hover .accordion-item__title {
  opacity: 0.8;
}

.avatar__image {
  width: 48px;
  height: 48px;
  border-radius: 3px;
}

.avatar__icon {
  width: 48px;
  height: 48px;
}

.back-link.button--style-link {
  font-weight: 700;
  text-align: left;
}

.back-link .button__inner {
  justify-content: flex-start;
}

.back-link .button__icon {
  transition: transform 150ms ease-in-out;
}
.back-link:not(:disabled):hover .button__icon {
  transform: translateX(-8px);
}

.breadcrumbs {
  display: flex;
}

.breadcrumbs__list {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  max-width: 100%;
  list-style: none;
}

.breadcrumbs__item {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-left: 18px;
  min-width: 40px;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.breadcrumbs__item:before {
  position: absolute;
  left: 6px;
  content: "/";
}
.breadcrumbs__item:first-child {
  padding-left: 0;
}
.breadcrumbs__item:first-child:before {
  content: none;
}

.breadcrumbs__link {
  padding: 3px;
  color: #355365;
  border-radius: 4px;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
}
.breadcrumbs__link.is-link:hover {
  color: #425d6e;
  text-decoration: underline;
  cursor: pointer;
}
.breadcrumbs__item.is-current .breadcrumbs__link {
  color: #506a7a;
  text-decoration: underline;
}
html[data-whatintent=keyboard] .breadcrumbs__link.is-link:focus {
  outline-offset: -2px;
  outline: 2px solid #3f00ff;
}
.breadcrumbs__item.is-disabled .breadcrumbs__link {
  color: #899aa5;
}

.button {
  display: inline-block;
  position: relative;
  padding: 0;
  background-image: none;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: normal;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  appearance: none;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
  transition: background 150ms ease-in-out;
}
.button:disabled:hover, .button:disabled {
  background: #f5f7f7;
  color: #899aa5;
  cursor: not-allowed;
}
.button:active {
  transition: none;
}
.button.is-loading {
  overflow: hidden;
  cursor: inherit;
}
html[data-whatintent=keyboard] .button:focus {
  outline-offset: 1px;
  outline: 2px solid #3f00ff;
}

.button__inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
}
.button--size-small .button__inner {
  padding: 2px 12px;
}
.button--size-large .button__inner {
  padding: 10px 24px;
}
.button--with-icon .button__inner {
  padding: 4px;
}
.button--with-icon.button--size-small .button__inner {
  padding: 2px;
}
.button--with-icon.button--size-large .button__inner {
  padding: 8px;
}
.button__spinner.is-exiting ~ .button__inner, .button__spinner.is-entering ~ .button__inner {
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
.button__spinner.is-exiting ~ .button__inner {
  opacity: 1;
  transform: translateY(0);
}
.button__spinner.is-entered ~ .button__inner, .button__spinner.is-entering ~ .button__inner {
  opacity: 0;
  transform: translateY(-10px);
}
.button__spinner ~ .button__inner {
  opacity: 0;
}

.button--appearance-strong {
  background: #003832;
  color: #ffffff;
}
.button--appearance-strong:not(:disabled):hover {
  background: #004840;
}
.button--appearance-strong:not(:disabled):active {
  background: #005c52;
}

.button--appearance-medium {
  background: #ebeef0;
  color: #092d43;
}
.button--appearance-medium:not(:disabled):hover {
  background: #dfe4e7;
}
.button--appearance-medium:not(:disabled):active {
  background: #c2cbd0;
}

.button--appearance-subtle {
  background: transparent;
  color: #092d43;
}
.button--appearance-subtle:not(:disabled):hover {
  background: #ebeef0;
}
.button--appearance-subtle:not(:disabled):active {
  background: #dfe4e7;
}

.button--appearance-link {
  background: transparent;
  color: #2b3bcf;
}
.button--appearance-link:not(:disabled):hover {
  color: #1e2991;
  text-decoration: underline;
}
.button--appearance-link:not(:disabled):active {
  color: #1a247e;
  text-decoration: underline;
}

.button__icon {
  display: inline-block;
  flex: 0 0 24px;
  font-size: 24px;
}
.button--size-small .button__icon {
  font-size: 20px;
}

.button__icon--right {
  margin-left: 4px;
}
.button--hidden-label .button__icon--right {
  margin-left: 0;
}

.button__icon--left {
  margin-right: 4px;
}
.button--hidden-label .button__icon--left {
  margin-right: 0;
}

.button--hidden-label .button__text, .button--with-icon .button__text {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.button--block {
  width: 100%;
}

@media only screen and (max-width: 599px) {
  .button--block-xs {
    display: block;
    width: 100%;
  }
}

/**
 * Spinner
 */
.button__spinner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
.button__spinner .spinner__path {
  stroke: #ffffff;
}
.button--appearance-medium .button__spinner .spinner__path, .button--appearance-subtle .button__spinner .spinner__path {
  stroke: #092d43;
}
.button--appearance-link .button__spinner .spinner__path {
  stroke: #2b3bcf;
}
.button__spinner.is-entering, .button__spinner.is-exited {
  opacity: 0;
  transform: translateY(10px);
}
.button__spinner.is-entered, .button__spinner.is-exiting {
  opacity: 1;
  transform: translateY(0);
}

.card {
  display: block;
  width: 100%;
  background: #fafbfb;
  box-shadow: 0 1px 1px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
}

.card--with-link {
  position: relative;
}
.card--with-link a:not(.card__link),
.card--with-link button {
  position: relative;
  z-index: 1;
}

.card--variant-secondary {
  background-color: rgba(238, 241, 244, 0.25);
  border: 1px solid #ececec;
  border-radius: 12px;
}

.card--padding-huge {
  border: none;
}

.card__content {
  padding: 24px;
}
.card--padding-huge .card__content {
  padding: 48px;
}

.card__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0;
  padding: 0 24px;
}
@media only screen and (min-width: 800px) {
  .container {
    padding: 0 48px;
  }
}

.container--align-center {
  margin: 0 auto;
}

.dropdown {
  display: inline-flex;
}

.dropdown__icon {
  font-size: 24px;
}
.dropdown--variant-alternative .dropdown__icon {
  font-size: 18px;
}

.dropdown__menu {
  min-width: 156px;
}

.dropdown__tooltip .tooltip__content {
  border-radius: 3px;
}

.dropdown .tooltip-toggle {
  padding: 0;
}
.dropdown .tooltip-toggle:hover {
  opacity: 0.8;
}
.dropdown--variant-alternative .tooltip-toggle {
  padding: 8px;
  background: #d9d9d9;
  border-radius: 50%;
}

.form__link {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  font-weight: 600;
  color: #667080;
  text-decoration: underline;
  cursor: pointer;
}

.checkbox {
  position: relative;
}

.checkbox__input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.checkbox__label {
  display: inline-block;
  padding-left: 32px;
  cursor: pointer;
}
.checkbox.is-disabled .checkbox__label {
  color: #899aa5;
  cursor: not-allowed;
}

.checkbox__indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 2px solid #dfe4e7;
  border-radius: 3px;
  outline: none;
  pointer-events: none;
  transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out;
}
.checkbox:not(.is-disabled) .checkbox__label:hover ~ .checkbox__indicator {
  background-color: #ebeef0;
  border-color: #c2cbd0;
}
.checkbox.is-disabled .checkbox__indicator {
  background-color: #f5f7f7;
  border-color: #ebeef0;
  color: #899aa5;
}

.checkbox__icon {
  opacity: 0;
  flex: 0 0 24px;
  font-size: 24px;
  color: #2b786f;
}
.checkbox__input:checked ~ .checkbox__indicator .checkbox__icon {
  opacity: 1;
}

.checkbox__indeterminate {
  opacity: 1;
  flex: 0 0 10px;
  width: 10px;
  height: 10px;
  background: #2b786f;
  border-radius: 3px;
}

.checkbox__note {
  margin-top: 4px;
  color: #7a8e99;
  font-size: 12px;
  line-height: 16px;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.checkbox-group__label {
  font-size: 14px;
  line-height: 22px;
  color: #667080;
}

.checkbox-group__error {
  margin-top: 4px;
  color: #de350b;
  font-size: 12px;
  line-height: 16px;
}

.rdp {
  --rdp-cell-size: 32px;
  --rdp-accent-color: #003832;
  --rdp-background-color: #ebeef0;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #003832;
  --rdp-background-color-dark: #ebeef0;
  /* Outline border for focused elements */
  --rdp-outline: 1px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 1px solid #2b786f;
}

.datepicker__popper {
  z-index: 10;
  will-change: transform, opacity;
  opacity: 0;
  transition: opacity 100ms ease-out;
}
.datepicker__popper.is-shown.is-entered {
  opacity: 1;
}

.datepicker__content {
  margin: 8px 0;
  padding: 24px 16px 20px;
  background-color: #ffffff;
  box-shadow: 0 8px 12px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  transition: transform 100ms ease-out;
}
.datepicker__popper[data-popper-placement=top-start] .datepicker__content {
  transform: translateY(20px);
}
.datepicker__popper[data-popper-placement=bottom-start] .datepicker__content {
  transform: translateY(-20px);
}
.datepicker__popper[data-popper-placement=bottom-start].is-shown.is-entered .datepicker__content, .datepicker__popper[data-popper-placement=top-start].is-shown.is-entered .datepicker__content {
  transform: translateY(0);
}

.datepicker__head-cell {
  height: 32px;
  color: #5d7483;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  vertical-align: middle;
}

.datepicker__months {
  position: relative;
}

.datepicker__day {
  width: 41px;
  height: var(--rdp-cell-size);
  border-radius: 3px;
  color: #092d43;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: background-color 100ms ease-in-out;
}
.datepicker__day:not(.rdp-day_disabled):active {
  background-color: #dfe4e7;
}
.datepicker__day.rdp-day_disabled {
  color: #899aa5;
  text-decoration: line-through;
}

.datepicker--today {
  font-weight: 400;
  outline-style: solid !important;
  outline-width: 1px !important;
  outline-color: #dfe4e7;
  outline-offset: -2px;
}
.datepicker--today:not(.rdp-day_disabled):hover {
  outline-color: #c2cbd0 !important;
}
.datepicker--today:not(.rdp-day_disabled).datepicker__day--selected, .datepicker--today:not(.rdp-day_disabled):active {
  outline-color: #2b786f !important;
}

.datepicker__day--outside {
  color: #5d7483;
}

.datepicker__day--selected {
  background-color: #003832;
  color: #ffffff;
}

.datepicker__caption {
  padding-bottom: 8px;
}

.datepicker__nav {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.password {
  position: relative;
}

.password__button-container {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.password .textfield__input {
  padding-right: 50px;
}

.select {
  position: relative;
  font-size: 14px;
  width: 100%;
  max-width: 100%;
}

.select__inner {
  position: relative;
}

.select--hidden-label .select__label {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.select__indicators {
  display: flex;
  flex-wrap: nowrap;
  flex: 0 0 auto;
  padding: 8px 8px 8px 0;
  font-size: 24px;
}

.select__arrow {
  flex: 0 0 24px;
  pointer-events: none;
  color: #092d43;
}
.select.is-open .select__arrow {
  transform: rotate(180deg);
}

.select__clear-indicator {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  font-size: 12px;
}

.select__clear-indicator,
.select__dropdown-indicator {
  padding: 0 !important;
}

.select--address .select__dropdown-indicator {
  display: none;
}

.select__wrapper {
  display: block;
  margin: 0;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 2px solid #dfe4e7;
  border-radius: 3px;
  outline: none;
  color: #092d43;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-appearance: none;
  appearance: none;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out;
}
.select.is-open .select__wrapper {
  outline: none;
  border-color: #2b786f;
}
.select__wrapper:focus-within {
  border-color: #2b786f;
}
.select.is-disabled .select__wrapper {
  background-color: #f5f7f7;
  border-color: #ebeef0;
  color: #899aa5;
  cursor: not-allowed;
}
.select.is-invalid .select__wrapper {
  border-color: #de350b;
}
.select__wrapper::-ms-clear {
  display: none;
}
.select__wrapper:hover {
  background-color: #ebeef0;
  border-color: #c2cbd0;
}
html[data-whatintent=keyboard] .select__wrapper:focus, .select__wrapper:focus {
  border-color: #2b786f;
}

.select__control {
  display: flex;
  position: relative;
  width: 100%;
  height: 40px;
  top: -2px;
}

.select__value-container {
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.select__multi-value,
.select__single-value {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
  white-space: nowrap;
}

.select__multi-value {
  display: inline;
  position: relative;
  flex: 0 1 auto;
  min-width: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select__multi-value:not(:first-child)::before {
  content: ",";
  margin-right: 4px;
}

.select__text-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
}

.select__menu {
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #ffffff;
  box-shadow: 0 8px 12px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  max-height: 400px;
  width: 100%;
  padding: 8px 0;
  -webkit-overflow-scrolling: touch;
  font-size: 14px;
}

.select__menu-list {
  max-height: 400px;
  overflow-y: auto;
}

.select__option {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 6px 16px;
  cursor: pointer;
}

.select__option--is-selected {
  background-color: #e6efee;
}

.select__option--is-focused {
  background-color: #ebeef0;
}
.select__option--is-focused:active {
  background-color: #dfe4e7;
}

.select__option--is-disabled {
  color: #899aa5;
  cursor: not-allowed;
}

.select__option--indented-1 {
  padding-left: 20px;
}

.select__option--indented-2 {
  padding-left: 40px;
}

.select__loading-message,
.select__menu-notice--no-options {
  display: block;
  padding: 6px 16px;
  color: #355365;
  text-align: center;
  cursor: default;
}

.select__placeholder {
  color: #5d7483;
}

.select__spinner {
  margin: auto 6px;
}

.textarea__input {
  display: block;
  min-height: 96px;
  padding: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  resize: vertical;
  overflow: visible;
  text-overflow: clip;
  white-space: normal;
}

.toggle {
  position: relative;
}

.toggle__label {
  position: relative;
  display: inline-flex;
  padding-left: 52px;
  line-height: 1;
  cursor: pointer;
  transition: color 150ms ease-in-out;
}
.toggle__input:disabled ~ .toggle__label {
  cursor: not-allowed;
}

.toggle__indicator {
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
  width: 32px;
  height: 16px;
  background: rgba(102, 112, 128, 0.3);
  border-radius: 8px;
  border: 2px solid transparent;
  box-shadow: none;
  user-select: none;
  transition: background 0.2s ease;
}
.toggle__indicator:after {
  content: "";
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  border-radius: 99em;
  background: #ffffff;
  transition: all 0.2s ease;
}
.toggle--variant-partial .toggle__indicator {
  background: #0077ff;
}
.toggle--variant-partial .toggle__indicator:after {
  left: 50%;
  transform: translate(-50%, -50%);
}
.toggle__input:checked ~ .toggle__label .toggle__indicator {
  background-color: #667080;
}
.toggle__input:checked ~ .toggle__label .toggle__indicator:after {
  left: 100%;
  transform: translate(-100%, -50%);
  margin-left: 0;
}
.toggle__input:disabled ~ .toggle__label .toggle__indicator {
  opacity: 0.3;
}
.toggle--variant-partial .toggle__input:disabled ~ .toggle__label .toggle__indicator {
  opacity: 1;
}

.toggle__text {
  display: inline-flex;
  align-items: center;
  flex: 0 0 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.toggle--variant-partial .toggle__text {
  font-weight: 700;
  color: #0077ff;
}
.toggle__input:checked ~ .toggle__label .toggle__text {
  font-weight: 700;
  color: inherit;
}
.toggle__input:disabled ~ .toggle__label .toggle__text {
  color: rgba(0, 0, 0, 0.5);
}
.toggle--variant-partial .toggle__input:disabled ~ .toggle__label .toggle__text {
  color: #0077ff;
}

.toggle__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.grid {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.grid--gutter-default {
  margin-right: -12px;
  margin-left: -12px;
  margin-bottom: -24px;
}

.grid--gutter-default > .grid__col {
  padding-right: 12px;
  padding-left: 12px;
  margin-bottom: 24px;
}

.grid--gutter-tiny {
  margin-right: -3px;
  margin-left: -3px;
  margin-bottom: -6px;
}

.grid--gutter-tiny > .grid__col {
  padding-right: 3px;
  padding-left: 3px;
  margin-bottom: 6px;
}

.grid--gutter-xsmall {
  margin-right: -6px;
  margin-left: -6px;
  margin-bottom: -12px;
}

.grid--gutter-xsmall > .grid__col {
  padding-right: 6px;
  padding-left: 6px;
  margin-bottom: 12px;
}

.grid--gutter-small {
  margin-right: -6px;
  margin-left: -6px;
  margin-bottom: -12px;
}

.grid--gutter-small > .grid__col {
  padding-right: 6px;
  padding-left: 6px;
  margin-bottom: 12px;
}

.grid--gutter-none {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0;
}

.grid--gutter-none > .grid__col {
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 0;
}

.grid--no-wrap {
  flex-wrap: nowrap;
}

.grid--gutter-none {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0;
}

.grid__col {
  flex: 0 0 auto;
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;
  outline: none;
}
.grid--equalheight > .grid__col {
  display: flex;
}
.grid--gutter-none > .grid__col {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}

.grid__col--min {
  flex-grow: 0;
  flex-basis: auto;
  width: auto;
  max-width: 100%;
}

.grid__col--max {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.grid__col--xs-1 {
  order: auto;
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
}

.grid__col--xs-2 {
  order: auto;
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
}

.grid__col--xs-3 {
  order: auto;
  flex-basis: 25%;
  max-width: 25%;
}

.grid__col--xs-4 {
  order: auto;
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
}

.grid__col--xs-5 {
  order: auto;
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
}

.grid__col--xs-6 {
  order: auto;
  flex-basis: 50%;
  max-width: 50%;
}

.grid__col--xs-7 {
  order: auto;
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
}

.grid__col--xs-8 {
  order: auto;
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
}

.grid__col--xs-9 {
  order: auto;
  flex-basis: 75%;
  max-width: 75%;
}

.grid__col--xs-10 {
  order: auto;
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
}

.grid__col--xs-11 {
  order: auto;
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
}

.grid__col--xs-12 {
  order: auto;
  flex-basis: 100%;
  max-width: 100%;
}

.grid__col--offset-xs-1 {
  margin-left: 8.3333333333%;
}

.grid__col--offset-xs-2 {
  margin-left: 16.6666666667%;
}

.grid__col--offset-xs-3 {
  margin-left: 25%;
}

.grid__col--offset-xs-4 {
  margin-left: 33.3333333333%;
}

.grid__col--offset-xs-5 {
  margin-left: 41.6666666667%;
}

.grid__col--offset-xs-6 {
  margin-left: 50%;
}

.grid__col--offset-xs-7 {
  margin-left: 58.3333333333%;
}

.grid__col--offset-xs-8 {
  margin-left: 66.6666666667%;
}

.grid__col--offset-xs-9 {
  margin-left: 75%;
}

.grid__col--offset-xs-10 {
  margin-left: 83.3333333333%;
}

.grid__col--offset-xs-11 {
  margin-left: 91.6666666667%;
}

.grid__col--offset-xs-12 {
  margin-left: 100%;
}

.grid__col--xs {
  flex-grow: 0;
  flex-basis: auto;
  width: auto;
  max-width: 100%;
}

.grid--no-wrap-xs {
  flex-wrap: nowrap;
}

.grid--wrap-xs {
  flex-wrap: wrap;
}

.grid__col--min-xs {
  flex-grow: 0;
  flex-basis: auto;
  width: auto;
  max-width: 100%;
}

.grid__col--max-xs {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: 100%;
}

.grid__col--top-xs {
  align-self: flex-start;
}

.grid__col--middle-xs {
  align-self: center;
}

.grid__col--bottom-xs {
  align-self: flex-end;
}

.grid__col--original-xs {
  order: 0;
}

.grid__col--first-xs {
  order: -1;
}

.grid__col--last-xs {
  order: 1;
}

.grid--reverse-xs {
  flex-direction: row-reverse;
}

.grid--start-xs {
  justify-content: flex-start;
}

.grid--center-xs {
  justify-content: center;
}

.grid--end-xs {
  justify-content: flex-end;
}

.grid--around-xs {
  justify-content: space-around;
}

.grid--between-xs {
  justify-content: space-between;
}

.grid--top-xs {
  align-items: flex-start;
}

.grid--middle-xs {
  align-items: center;
}

.grid--bottom-xs {
  align-items: flex-end;
}

@media only screen and (min-width: 600px) {
  .grid__col--sm-1 {
    order: auto;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .grid__col--sm-2 {
    order: auto;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .grid__col--sm-3 {
    order: auto;
    flex-basis: 25%;
    max-width: 25%;
  }
  .grid__col--sm-4 {
    order: auto;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .grid__col--sm-5 {
    order: auto;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .grid__col--sm-6 {
    order: auto;
    flex-basis: 50%;
    max-width: 50%;
  }
  .grid__col--sm-7 {
    order: auto;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .grid__col--sm-8 {
    order: auto;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .grid__col--sm-9 {
    order: auto;
    flex-basis: 75%;
    max-width: 75%;
  }
  .grid__col--sm-10 {
    order: auto;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .grid__col--sm-11 {
    order: auto;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .grid__col--sm-12 {
    order: auto;
    flex-basis: 100%;
    max-width: 100%;
  }
  .grid__col--offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .grid__col--offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .grid__col--offset-sm-3 {
    margin-left: 25%;
  }
  .grid__col--offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .grid__col--offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .grid__col--offset-sm-6 {
    margin-left: 50%;
  }
  .grid__col--offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .grid__col--offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .grid__col--offset-sm-9 {
    margin-left: 75%;
  }
  .grid__col--offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .grid__col--offset-sm-11 {
    margin-left: 91.6666666667%;
  }
  .grid__col--offset-sm-12 {
    margin-left: 100%;
  }
  .grid__col--sm {
    flex-grow: 0;
    flex-basis: auto;
    width: auto;
    max-width: 100%;
  }
  .grid--no-wrap-sm {
    flex-wrap: nowrap;
  }
  .grid--wrap-sm {
    flex-wrap: wrap;
  }
  .grid__col--min-sm {
    flex-grow: 0;
    flex-basis: auto;
    width: auto;
    max-width: 100%;
  }
  .grid__col--max-sm {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
  .grid__col--top-sm {
    align-self: flex-start;
  }
  .grid__col--middle-sm {
    align-self: center;
  }
  .grid__col--bottom-sm {
    align-self: flex-end;
  }
  .grid__col--original-sm {
    order: 0;
  }
  .grid__col--first-sm {
    order: -1;
  }
  .grid__col--last-sm {
    order: 1;
  }
  .grid--reverse-sm {
    flex-direction: row-reverse;
  }
  .grid--start-sm {
    justify-content: flex-start;
  }
  .grid--center-sm {
    justify-content: center;
  }
  .grid--end-sm {
    justify-content: flex-end;
  }
  .grid--around-sm {
    justify-content: space-around;
  }
  .grid--between-sm {
    justify-content: space-between;
  }
  .grid--top-sm {
    align-items: flex-start;
  }
  .grid--middle-sm {
    align-items: center;
  }
  .grid--bottom-sm {
    align-items: flex-end;
  }
}
@media only screen and (min-width: 800px) {
  .grid__col--md-1 {
    order: auto;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .grid__col--md-2 {
    order: auto;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .grid__col--md-3 {
    order: auto;
    flex-basis: 25%;
    max-width: 25%;
  }
  .grid__col--md-4 {
    order: auto;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .grid__col--md-5 {
    order: auto;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .grid__col--md-6 {
    order: auto;
    flex-basis: 50%;
    max-width: 50%;
  }
  .grid__col--md-7 {
    order: auto;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .grid__col--md-8 {
    order: auto;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .grid__col--md-9 {
    order: auto;
    flex-basis: 75%;
    max-width: 75%;
  }
  .grid__col--md-10 {
    order: auto;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .grid__col--md-11 {
    order: auto;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .grid__col--md-12 {
    order: auto;
    flex-basis: 100%;
    max-width: 100%;
  }
  .grid__col--offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .grid__col--offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .grid__col--offset-md-3 {
    margin-left: 25%;
  }
  .grid__col--offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .grid__col--offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .grid__col--offset-md-6 {
    margin-left: 50%;
  }
  .grid__col--offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .grid__col--offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .grid__col--offset-md-9 {
    margin-left: 75%;
  }
  .grid__col--offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .grid__col--offset-md-11 {
    margin-left: 91.6666666667%;
  }
  .grid__col--offset-md-12 {
    margin-left: 100%;
  }
  .grid__col--md {
    flex-grow: 0;
    flex-basis: auto;
    width: auto;
    max-width: 100%;
  }
  .grid--no-wrap-md {
    flex-wrap: nowrap;
  }
  .grid--wrap-md {
    flex-wrap: wrap;
  }
  .grid__col--min-md {
    flex-grow: 0;
    flex-basis: auto;
    width: auto;
    max-width: 100%;
  }
  .grid__col--max-md {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
  .grid__col--top-md {
    align-self: flex-start;
  }
  .grid__col--middle-md {
    align-self: center;
  }
  .grid__col--bottom-md {
    align-self: flex-end;
  }
  .grid__col--original-md {
    order: 0;
  }
  .grid__col--first-md {
    order: -1;
  }
  .grid__col--last-md {
    order: 1;
  }
  .grid--reverse-md {
    flex-direction: row-reverse;
  }
  .grid--start-md {
    justify-content: flex-start;
  }
  .grid--center-md {
    justify-content: center;
  }
  .grid--end-md {
    justify-content: flex-end;
  }
  .grid--around-md {
    justify-content: space-around;
  }
  .grid--between-md {
    justify-content: space-between;
  }
  .grid--top-md {
    align-items: flex-start;
  }
  .grid--middle-md {
    align-items: center;
  }
  .grid--bottom-md {
    align-items: flex-end;
  }
}
@media only screen and (min-width: 1000px) {
  .grid__col--lg-1 {
    order: auto;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .grid__col--lg-2 {
    order: auto;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .grid__col--lg-3 {
    order: auto;
    flex-basis: 25%;
    max-width: 25%;
  }
  .grid__col--lg-4 {
    order: auto;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .grid__col--lg-5 {
    order: auto;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .grid__col--lg-6 {
    order: auto;
    flex-basis: 50%;
    max-width: 50%;
  }
  .grid__col--lg-7 {
    order: auto;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .grid__col--lg-8 {
    order: auto;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .grid__col--lg-9 {
    order: auto;
    flex-basis: 75%;
    max-width: 75%;
  }
  .grid__col--lg-10 {
    order: auto;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .grid__col--lg-11 {
    order: auto;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .grid__col--lg-12 {
    order: auto;
    flex-basis: 100%;
    max-width: 100%;
  }
  .grid__col--offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .grid__col--offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .grid__col--offset-lg-3 {
    margin-left: 25%;
  }
  .grid__col--offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .grid__col--offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .grid__col--offset-lg-6 {
    margin-left: 50%;
  }
  .grid__col--offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .grid__col--offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .grid__col--offset-lg-9 {
    margin-left: 75%;
  }
  .grid__col--offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .grid__col--offset-lg-11 {
    margin-left: 91.6666666667%;
  }
  .grid__col--offset-lg-12 {
    margin-left: 100%;
  }
  .grid__col--lg {
    flex-grow: 0;
    flex-basis: auto;
    width: auto;
    max-width: 100%;
  }
  .grid--no-wrap-lg {
    flex-wrap: nowrap;
  }
  .grid--wrap-lg {
    flex-wrap: wrap;
  }
  .grid__col--min-lg {
    flex-grow: 0;
    flex-basis: auto;
    width: auto;
    max-width: 100%;
  }
  .grid__col--max-lg {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
  .grid__col--top-lg {
    align-self: flex-start;
  }
  .grid__col--middle-lg {
    align-self: center;
  }
  .grid__col--bottom-lg {
    align-self: flex-end;
  }
  .grid__col--original-lg {
    order: 0;
  }
  .grid__col--first-lg {
    order: -1;
  }
  .grid__col--last-lg {
    order: 1;
  }
  .grid--reverse-lg {
    flex-direction: row-reverse;
  }
  .grid--start-lg {
    justify-content: flex-start;
  }
  .grid--center-lg {
    justify-content: center;
  }
  .grid--end-lg {
    justify-content: flex-end;
  }
  .grid--around-lg {
    justify-content: space-around;
  }
  .grid--between-lg {
    justify-content: space-between;
  }
  .grid--top-lg {
    align-items: flex-start;
  }
  .grid--middle-lg {
    align-items: center;
  }
  .grid--bottom-lg {
    align-items: flex-end;
  }
}
@media only screen and (min-width: 1200px) {
  .grid__col--xl-1 {
    order: auto;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .grid__col--xl-2 {
    order: auto;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .grid__col--xl-3 {
    order: auto;
    flex-basis: 25%;
    max-width: 25%;
  }
  .grid__col--xl-4 {
    order: auto;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .grid__col--xl-5 {
    order: auto;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .grid__col--xl-6 {
    order: auto;
    flex-basis: 50%;
    max-width: 50%;
  }
  .grid__col--xl-7 {
    order: auto;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .grid__col--xl-8 {
    order: auto;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .grid__col--xl-9 {
    order: auto;
    flex-basis: 75%;
    max-width: 75%;
  }
  .grid__col--xl-10 {
    order: auto;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .grid__col--xl-11 {
    order: auto;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .grid__col--xl-12 {
    order: auto;
    flex-basis: 100%;
    max-width: 100%;
  }
  .grid__col--offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .grid__col--offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .grid__col--offset-xl-3 {
    margin-left: 25%;
  }
  .grid__col--offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .grid__col--offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .grid__col--offset-xl-6 {
    margin-left: 50%;
  }
  .grid__col--offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .grid__col--offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .grid__col--offset-xl-9 {
    margin-left: 75%;
  }
  .grid__col--offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .grid__col--offset-xl-11 {
    margin-left: 91.6666666667%;
  }
  .grid__col--offset-xl-12 {
    margin-left: 100%;
  }
  .grid__col--xl {
    flex-grow: 0;
    flex-basis: auto;
    width: auto;
    max-width: 100%;
  }
  .grid--no-wrap-xl {
    flex-wrap: nowrap;
  }
  .grid--wrap-xl {
    flex-wrap: wrap;
  }
  .grid__col--min-xl {
    flex-grow: 0;
    flex-basis: auto;
    width: auto;
    max-width: 100%;
  }
  .grid__col--max-xl {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
  .grid__col--top-xl {
    align-self: flex-start;
  }
  .grid__col--middle-xl {
    align-self: center;
  }
  .grid__col--bottom-xl {
    align-self: flex-end;
  }
  .grid__col--original-xl {
    order: 0;
  }
  .grid__col--first-xl {
    order: -1;
  }
  .grid__col--last-xl {
    order: 1;
  }
  .grid--reverse-xl {
    flex-direction: row-reverse;
  }
  .grid--start-xl {
    justify-content: flex-start;
  }
  .grid--center-xl {
    justify-content: center;
  }
  .grid--end-xl {
    justify-content: flex-end;
  }
  .grid--around-xl {
    justify-content: space-around;
  }
  .grid--between-xl {
    justify-content: space-between;
  }
  .grid--top-xl {
    align-items: flex-start;
  }
  .grid--middle-xl {
    align-items: center;
  }
  .grid--bottom-xl {
    align-items: flex-end;
  }
}
@media only screen and (min-width: 1400px) {
  .grid__col--xxl-1 {
    order: auto;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .grid__col--xxl-2 {
    order: auto;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .grid__col--xxl-3 {
    order: auto;
    flex-basis: 25%;
    max-width: 25%;
  }
  .grid__col--xxl-4 {
    order: auto;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .grid__col--xxl-5 {
    order: auto;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .grid__col--xxl-6 {
    order: auto;
    flex-basis: 50%;
    max-width: 50%;
  }
  .grid__col--xxl-7 {
    order: auto;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .grid__col--xxl-8 {
    order: auto;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .grid__col--xxl-9 {
    order: auto;
    flex-basis: 75%;
    max-width: 75%;
  }
  .grid__col--xxl-10 {
    order: auto;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .grid__col--xxl-11 {
    order: auto;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .grid__col--xxl-12 {
    order: auto;
    flex-basis: 100%;
    max-width: 100%;
  }
  .grid__col--offset-xxl-1 {
    margin-left: 8.3333333333%;
  }
  .grid__col--offset-xxl-2 {
    margin-left: 16.6666666667%;
  }
  .grid__col--offset-xxl-3 {
    margin-left: 25%;
  }
  .grid__col--offset-xxl-4 {
    margin-left: 33.3333333333%;
  }
  .grid__col--offset-xxl-5 {
    margin-left: 41.6666666667%;
  }
  .grid__col--offset-xxl-6 {
    margin-left: 50%;
  }
  .grid__col--offset-xxl-7 {
    margin-left: 58.3333333333%;
  }
  .grid__col--offset-xxl-8 {
    margin-left: 66.6666666667%;
  }
  .grid__col--offset-xxl-9 {
    margin-left: 75%;
  }
  .grid__col--offset-xxl-10 {
    margin-left: 83.3333333333%;
  }
  .grid__col--offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
  .grid__col--offset-xxl-12 {
    margin-left: 100%;
  }
  .grid__col--xxl {
    flex-grow: 0;
    flex-basis: auto;
    width: auto;
    max-width: 100%;
  }
  .grid--no-wrap-xxl {
    flex-wrap: nowrap;
  }
  .grid--wrap-xxl {
    flex-wrap: wrap;
  }
  .grid__col--min-xxl {
    flex-grow: 0;
    flex-basis: auto;
    width: auto;
    max-width: 100%;
  }
  .grid__col--max-xxl {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
  .grid__col--top-xxl {
    align-self: flex-start;
  }
  .grid__col--middle-xxl {
    align-self: center;
  }
  .grid__col--bottom-xxl {
    align-self: flex-end;
  }
  .grid__col--original-xxl {
    order: 0;
  }
  .grid__col--first-xxl {
    order: -1;
  }
  .grid__col--last-xxl {
    order: 1;
  }
  .grid--reverse-xxl {
    flex-direction: row-reverse;
  }
  .grid--start-xxl {
    justify-content: flex-start;
  }
  .grid--center-xxl {
    justify-content: center;
  }
  .grid--end-xxl {
    justify-content: flex-end;
  }
  .grid--around-xxl {
    justify-content: space-around;
  }
  .grid--between-xxl {
    justify-content: space-between;
  }
  .grid--top-xxl {
    align-items: flex-start;
  }
  .grid--middle-xxl {
    align-items: center;
  }
  .grid--bottom-xxl {
    align-items: flex-end;
  }
}
.image {
  display: block;
  max-width: 100%;
  height: auto;
}
.image.lazyload, .image.lazyloading {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}
.image.lazyloaded {
  opacity: 1;
  transition: opacity 400ms ease-in-out;
}
.image.image--no-fade {
  transition: none;
}

.image--left {
  margin-right: auto;
}

.image--center {
  margin-left: auto;
  margin-right: auto;
}

.image--right {
  margin-left: auto;
}

.image-upload {
  width: 100%;
}

.image-upload__input {
  width: 100%;
  margin-bottom: 1em;
}

.image-upload__cropper {
  width: 100%;
}

.image-upload__cropper-button {
  margin: 1em 0;
}

.kb-menu--nested {
  padding-left: 16px;
}

.kb-menu__item {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.kb-menu__item:not(.kb-menu__item--locked):hover {
  opacity: 0.8;
}

.kb-menu__item--current {
  background-color: #d0d7db;
}

.kb-menu__item--locked {
  opacity: 0.5;
  cursor: not-allowed;
}

.kb-menu__icon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.kb-menu__item-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.logo {
  display: block;
  position: relative;
  max-width: 195px;
  fill: currentColor;
  text-decoration: none;
}
.logo:before {
  display: block;
  width: 100%;
  content: "";
  padding-top: 19.4871794872%;
}

.logo--variant-favicon {
  max-width: 40px;
}
.logo--variant-favicon:before {
  padding-top: 100%;
}

.logo__image {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.main {
  padding-top: 16px;
  padding-bottom: 16px;
  min-width: 100%;
  min-height: 100vh;
  min-height: var(--app-height);
  background: #ffffff;
  transition: padding 150ms ease-in-out;
}
@media only screen and (min-width: 800px) {
  .main {
    padding-left: 64px;
  }
}

@media only screen and (min-width: 800px) {
  .main--with-submenu {
    padding-left: 324px;
  }
}

@media only screen and (min-width: 800px) {
  .main--kb-preview {
    padding-left: 260px;
  }
}

.main--background-grey {
  background-color: #e6efee;
}

.main--align-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.menu-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.menu-item.is-disabled {
  opacity: 0.5;
}

.menu-item__inner {
  display: flex;
  align-items: center;
}

.menu-item__trigger {
  flex: 0 0 100%;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  position: relative;
  padding: 8px;
  border-radius: 3px;
  background: transparent;
  background-image: none;
  border: none;
  color: #092d43;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-decoration: none;
  text-transform: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  transition: background-color 150ms ease-in-out;
}
.menu-item__trigger:not(:disabled):hover {
  opacity: 0.8;
}
.menu-item__trigger:disabled:hover, .menu-item__trigger:disabled {
  cursor: not-allowed;
}
.menu-item.is-open .menu-item__trigger {
  background-color: #dfe4e7;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.menu-item.is-current .menu-item__trigger {
  background-color: #d0d7db;
}
html[data-whatintent=keyboard] .menu-item__trigger:focus {
  outline-offset: 1px;
  outline: 2px solid #3f00ff;
}

.menu-item__icon {
  flex: 0 0 18px;
  margin-right: 16px;
  font-size: 18px;
}

.menu-item__children {
  flex: 0 1 100%;
  max-width: 100%;
  transition: height 150ms ease-in-out;
}

.menu-item__name {
  flex: 1 1 100%;
  padding: 2px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menu-item__arrow {
  flex: 0 0 24px;
  font-size: 24px;
  margin-left: 16px;
  transition: transform 150ms ease-in-out;
}
.menu-item.is-open .menu-item__arrow {
  transform: rotate(180deg);
}

.modal {
  opacity: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 15;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  will-change: opacity;
  transition: opacity 150ms ease-out;
}
.modal.is-entering, .modal.is-entered {
  opacity: 1;
}
.modal.is-entered {
  overflow: visible;
}
.modal.is-entering, .modal.is-exiting {
  overflow: hidden;
}
.modal:focus {
  outline: none;
}
.modal:last-of-type {
  position: relative;
}

.modal__backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(26, 42, 70, 0.5);
}

.modal__inner {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 1440px;
  min-height: 100vh;
  margin: auto;
}
@media only screen and (min-width: 800px) {
  .modal__inner {
    padding: 72px 24px 48px;
  }
}

.modal__box {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0 8px 12px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  transform: translateY(24px);
  transition: transform 300ms ease-out;
}
@media only screen and (max-width: 799px) {
  .modal__box {
    margin: auto;
  }
}
.modal.is-entered .modal__box, .modal.is-entering .modal__box {
  transform: translateY(0);
}
.modal--width-narrow .modal__box {
  max-width: 400px;
}
.modal--width-medium .modal__box {
  max-width: 500px;
}
.modal--width-wide .modal__box {
  max-width: 600px;
}

.modal__content {
  padding: 68px 24px 24px;
}
.modal__header + .modal__content {
  padding-top: 16px;
}

.modal__header {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-radius: 3px 3px 0 0;
}
.modal.is-closable .modal__header {
  padding-right: 58px;
}

.modal__footer {
  padding: 16px 24px;
  border-radius: 0 0 3px 3px;
}
.modal__content + .modal__footer {
  margin-top: -8px;
}

.modal__close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: 24px;
  padding: 0;
  background: transparent;
  color: #092d43;
  border: 0;
  font-size: 24px;
  outline: none;
  cursor: pointer;
  transition: color 150ms ease-out;
}
.modal__close:hover {
  color: #15384c;
}
.modal__close:active {
  transition-duration: 0ms;
  color: #244458;
}

.notification {
  display: inline-block;
  padding: 12px 16px;
  border-radius: 3px;
  width: auto;
  min-width: 360px;
  max-width: 500px;
  box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);
  color: #092d43;
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}
.notification.is-entering, .notification.is-entered {
  opacity: 1;
  transform: translateX(0);
}
.notification.is-exiting, .notification.is-exited {
  opacity: 0;
  transform: translateX(100%);
}

.notification--type-error {
  background-color: #ffebe6;
}

.notification--type-info,
.notification--type-success {
  background-color: #ffffff;
}

.notification__inner {
  display: flex;
  align-items: center;
}

.notification__icon-cell {
  display: inline-flex;
  flex: 0 0 24px;
  margin-right: 16px;
}

.notification__icon {
  flex: 0 0 24px;
  font-size: 24px;
}
.notification--type-error .notification__icon {
  color: #ff5630;
}
.notification--type-success .notification__icon {
  color: #36b37e;
}

.notification__content {
  flex: 1;
  font-weight: 500;
}
.notification.is-closable .notification__content {
  position: relative;
  padding-right: 30px;
}

.notification__extra-content-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  padding: 0 40px;
}

.notification__extra-content {
  white-space: pre-wrap;
}

.notification__accordion {
  transition: height 100ms ease-out;
}

.notification__accordion-inner {
  display: block !important;
}

.notification--type-error .notification__extra-action.button--appearance-medium {
  background: #ffbdad;
}
.notification--type-error .notification__extra-action.button--appearance-medium:not(:disabled):hover {
  background: #ff8f73;
}
.notification--type-error .notification__extra-action.button--appearance-medium:not(:disabled):active {
  background: #ffbdad;
}

.notification__accordion-button .button__inner, .notification__close-button .button__inner {
  padding: 0;
}

.notification__accordion-button .button__icon {
  transition: transform 100ms ease-out;
}
.notification.is-expanded .notification__accordion-button .button__icon {
  transform: rotate(180deg);
}

.notification-list {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  z-index: 16;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 12px;
  gap: 8px;
  max-width: 100%;
  pointer-events: none;
}
.notification-list > * {
  pointer-events: auto;
}

.side-navigation {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  transition: background 150ms ease-in-out;
}
.side-navigation.is-open {
  background: rgba(26, 42, 70, 0.5);
  pointer-events: auto;
}
@media only screen and (min-width: 800px) {
  .side-navigation.is-open {
    background: transparent;
    pointer-events: none;
  }
}

.side-navigation__inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px 0;
  width: 160px;
  height: 100%;
  background: #003832;
  color: #ffffff;
  pointer-events: auto;
  transform: translateX(-100%);
  transition: transform 150ms ease-in-out;
}
@media only screen and (min-width: 800px) {
  .side-navigation__inner {
    padding-right: 8px;
    padding-left: 8px;
    transform: translateX(0);
    width: 64px;
  }
}
.side-navigation.is-open .side-navigation__inner {
  transform: translateX(0);
}

.side-navigation__toggle {
  position: absolute;
  top: 36px;
  left: 100%;
  transition: transform 150ms ease-in-out;
}
@media only screen and (min-width: 800px) {
  .side-navigation__toggle {
    display: none;
  }
}

.side-navigation__toggle--after-submenu {
  left: 420px;
}

.side-navigation__top {
  position: relative;
  margin: 4px 8px 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.side-navigation__top:after {
  position: absolute;
  right: 0;
  left: 0;
  bottom: -20px;
  height: 1px;
  background: #dfe4e7;
  content: "";
}

.side-navigation__sub-title {
  margin-top: 4px;
  color: #355365;
}

.side-navigation__secondary-title {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.side-navigation__navigation {
  width: 100%;
  flex-grow: 1;
}
@media only screen and (min-width: 800px) {
  .side-navigation__navigation {
    width: auto;
  }
}

.side-navigation__navigation--bottom {
  margin-top: 4px;
  flex-grow: 0;
}

.side-navigation__logo {
  margin-bottom: 12px;
  width: 40px;
}

.side-navigation__item + .side-navigation__item {
  margin-top: 4px;
}

.side-navigation__item .tooltip-toggle {
  padding: 0;
}

.side-navigation__button > .button__inner {
  justify-content: flex-start;
}

.side-navigation__tooltip .tooltip__content {
  margin: 0 8px;
}

.side-navigation__item.is-active .side-navigation__button:not(:hover) {
  background-color: #005c52;
}

.side-navigation__language-buttons {
  margin-bottom: 24px;
}

.side-navigation__language-button {
  width: 40px;
  height: 32px;
}

.side-navigation__language-button .button__inner {
  padding: 4px;
  justify-content: center;
}

.side-navigation__submenu {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 160px;
  padding: 24px 16px;
  width: 260px;
  background: #fafbfb;
  color: #092d43;
  outline: 1px solid #dfe4e7;
  transform: translateX(-100%);
  transition: opacity 150ms ease-in-out, visibility 150ms ease-in-out, transform 150ms ease-in-out;
  overflow-y: auto;
}
@media only screen and (min-width: 800px) {
  .side-navigation__submenu {
    z-index: 9;
    left: 64px;
  }
}
.side-navigation__submenu.is-open {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.os-scrollbar {
  --os-size: 8px;
  --os-track-border-radius: 0;
  --os-track-border: none;
  --os-handle-border-radius: 0;
  --os-handle-bg: #b3bec5;
  --os-handle-bg-hover: #98a7b0;
  --os-handle-bg-active: #7a8e99;
  --os-handle-perpendicular-size-hover: 200%;
  --os-handle-perpendicular-size-active: 200%;
}

.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.spinner__circle {
  margin: auto;
  width: 32px;
  height: 32px;
  animation: spinnerRotate 2s linear infinite;
  transform-origin: center center;
}
.spinner--size-small .spinner__circle {
  width: 24px;
  height: 24px;
}
.spinner--size-tiny .spinner__circle {
  width: 16px;
  height: 16px;
}

.spinner__path {
  stroke-dasharray: 89, 200;
  stroke-dashoffset: 0;
  animation: spinnerDash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke-width: 4px;
  stroke-miterlimit: 10;
  stroke: #092d43;
}

.spinner__text {
  display: block;
  margin-top: 20px;
}

@keyframes spinnerRotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinnerDash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -136;
  }
}
.status {
  display: inline-block;
  padding: 3px 5px;
  border-radius: 3px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
}

.status--type-alert {
  background: #ffebe6;
  color: #bf2600;
}

.status--type-done {
  background: #ebf7f2;
  color: #216d4d;
}

.status--type-incomplete {
  background: #fff7e6;
  color: #ad5809;
}

.submenu {
  background: #ebeef0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.submenu-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  transition: padding 150ms ease-in-out;
}
.submenu-item.is-disabled {
  opacity: 0.5;
}
.submenu-item:last-child.is-open {
  padding-bottom: 8px;
}
.submenu-item__children .submenu-item {
  padding: 0 8px;
}
.submenu-item__children .submenu-item.is-open {
  padding-bottom: 0;
}

.submenu-item__trigger {
  flex: 0 1 auto;
  display: inline-flex;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 10px 8px;
  background: transparent;
  background-image: none;
  border: none;
  font-weight: 600;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  appearance: none;
  outline: none;
  cursor: pointer;
  color: #355365;
  font-size: 12px;
  line-height: 16px;
}
.submenu-item__trigger:not(:disabled):hover {
  opacity: 0.8;
}
.submenu-item__trigger:disabled:hover, .submenu-item__trigger:disabled {
  cursor: not-allowed;
}
.submenu-item__children .submenu-item__trigger {
  border-radius: 3px;
  padding: 10px 12px;
  font-weight: 400;
  text-transform: none;
  font-size: 12px;
  line-height: 16px;
}
.submenu-item__children .submenu-item.is-current > .submenu-item__trigger {
  position: relative;
  background: #dfe4e7;
}
.submenu-item__children .submenu-item.is-current > .submenu-item__trigger:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 4px;
  height: 16px;
  background: #003832;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  transform: translateY(-50%);
  content: "";
}
.submenu-item__children--with-grandchildren > div > .submenu-item > .submenu-item__trigger {
  padding: 10px 8px 10px 24px;
  margin: 0;
  font-weight: 600;
  color: #5d7483;
  text-transform: uppercase;
}
.submenu-item__children--grandchild .submenu-item__trigger {
  padding: 10px 12px;
  margin: 0;
  font-weight: 400;
}
html[data-whatintent=keyboard] .submenu-item__trigger:focus {
  outline-offset: -2px;
  outline: 2px solid #3f00ff;
}

.submenu-item__name {
  flex: 1;
  padding: 2px 0;
}
.submenu-item__children .submenu-item__name {
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.submenu-item__name-prefix {
  align-self: flex-start;
  margin-right: 4px;
}

.submenu-item__inner {
  display: flex;
  align-items: center;
  width: 100%;
}
.submenu-item__children--grandchild .submenu-item__inner {
  overflow: hidden;
}

.submenu-item__icon {
  flex: 0 0 24px;
  font-size: 24px;
}

.submenu-item__icon--prefix {
  position: absolute;
  left: 0;
  align-self: flex-start;
  margin-top: -0.25em;
  transition: transform 150ms ease-in-out;
}
.submenu-item__children .submenu-item.is-open .submenu-item__icon--prefix {
  transform: rotate(90deg);
}

.submenu-item__icon--children {
  transition: transform 150ms ease-in-out;
}
.submenu-item.is-open > .submenu-item__trigger .submenu-item__icon--children {
  transform: rotate(180deg);
}

.submenu-item__children {
  flex: 0 1 100%;
  width: 100%;
  min-width: 1px;
  margin: 0 8px;
  border-radius: 3px;
  background-color: #f5f7f7;
  transition: height 150ms ease-in-out;
}

.submenu-item__children--grandchild {
  margin: 0;
}

.table {
  width: 100%;
  overflow: auto;
}

.table__table {
  width: 100%;
}

.table__cell {
  padding: 12px;
  border-bottom: 1px solid rgba(102, 112, 128, 0.4);
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.table__cell:first-child {
  padding-left: 0;
}
.table__cell:last-child {
  padding-right: 0;
}
.table__row:last-child .table__cell {
  border-bottom: none;
}
.table__row.selected .table__cell {
  background-color: #f1f5f9;
}

.table__header {
  padding: 0 12px 10px;
  border-bottom: 2px solid rgba(102, 112, 128, 0.4);
  vertical-align: bottom;
  font-size: 13px;
}
.table__header:first-child {
  padding-left: 0;
}
.table__header:last-child {
  padding-right: 0;
}

/* Type default */
/* Type incomplete */
/* Type done */
.tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: #ebeef0;
  border-radius: 3px;
  border: none;
  color: #5d7483;
  outline: none;
  text-decoration: none;
  appearance: none;
}
.tag.tag--with-tooltip, .tag.is-clickable {
  position: relative;
  cursor: pointer;
}
.tag.tag--with-tooltip:after, .tag.is-clickable:after {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 1px 1px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  pointer-events: none;
  content: "";
  transition: opacity 150ms ease-in-out;
}
.tag.tag--with-tooltip:hover, .tag.is-clickable:hover {
  background: rgba(235, 238, 240, 0.8);
}
.tag.tag--with-tooltip:hover:after, .tag.is-clickable:hover:after {
  opacity: 1;
}
.tag.tag--with-tooltip:active, .tag.is-clickable:active {
  background: #e1e4e6;
  transition: none;
}

.tag__tooltip-trigger.tooltip-toggle {
  padding: 0;
}

.tag__tooltip {
  text-align: center;
}

.tag--status-done {
  background: #ebf7f2;
  color: #216d4d;
}
.tag--status-done.tag--with-tooltip:hover, .tag--status-done.is-clickable:hover {
  background: rgba(235, 247, 242, 0.8);
}
.tag--status-done.tag--with-tooltip:active, .tag--status-done.is-clickable:active {
  background: #e2ede8;
}

.tag--status-incomplete {
  background: #fff3d8;
  color: #ad5809;
}
.tag--status-incomplete.tag--with-tooltip:hover, .tag--status-incomplete.is-clickable:hover {
  background: rgba(255, 243, 216, 0.8);
}
.tag--status-incomplete.tag--with-tooltip:active, .tag--status-incomplete.is-clickable:active {
  background: #f5e9cf;
}

.tag__text {
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}

.tag-collapse {
  display: flex;
}

.tag-collapse__inner {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-right: 0;
  padding: 0;
  overflow: auto;
  overflow-y: hidden;
}
.tag-collapse__tooltip .tag-collapse__inner {
  flex-wrap: wrap;
  gap: 6px;
  overflow: visible;
}

.tag-collapse__item + .tag-collapse__item {
  margin-left: 6px;
}
.tag-collapse__tooltip .tag-collapse__item + .tag-collapse__item {
  margin-left: 0;
}

.tag-collapse {
  position: relative;
}

.tag-collapse__toggle {
  margin-left: 6px;
}

.tag-collapse .tooltip-toggle {
  padding: 0;
}

.tooltip {
  display: flex;
  position: absolute;
  opacity: 0;
  z-index: 20;
  max-width: 200px;
  transition: opacity 100ms ease-out;
  will-change: transform, opacity;
}
.tooltip.is-shown.is-entered {
  opacity: 1;
}

.tooltip[data-popper-placement=top],
.tooltip[data-popper-placement=bottom] {
  flex-direction: column;
}

.tooltip-toggle {
  display: inline-flex;
  background: none;
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
}

.tooltip__content {
  padding: 2px 6px;
  max-width: 100%;
  background: #092d43;
  border-radius: 3px;
  color: #ffffff;
  font-size: 12px;
  line-height: 16px;
  box-sizing: border-box;
  overflow: hidden;
  transition: transform 150ms ease-in-out;
}
.tooltip[data-popper-placement=top] .tooltip__content {
  transform: translateY(-1);
}
.tooltip[data-popper-placement=right] .tooltip__content {
  transform: translateX(0);
}
.tooltip[data-popper-placement=bottom] .tooltip__content {
  transform: translateY(0);
}
.tooltip[data-popper-placement=left] .tooltip__content {
  transform: translateX(-1);
}
.tooltip[data-popper-placement=top].is-shown.is-entered .tooltip__content {
  transform: translateY(-8px);
}
.tooltip[data-popper-placement=right].is-shown.is-entered .tooltip__content {
  transform: translateX(8px);
}
.tooltip[data-popper-placement=bottom].is-shown.is-entered .tooltip__content {
  transform: translateY(8px);
}
.tooltip[data-popper-placement=left].is-shown.is-entered .tooltip__content {
  transform: translateX(-8px);
}
.tooltip--color-white .tooltip__content {
  background-color: #ffffff;
  color: #092d43;
  box-shadow: 0 8px 12px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
}

.tooltip__icon {
  color: #092d43;
  font-size: 16px;
}

.user-details {
  display: flex;
  position: relative;
  padding-right: 30px;
  padding-left: 12px;
}
.editor__user .user-details {
  padding-left: 0;
}

.user-details__description {
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: #5d7483;
}

.user-menu__separator {
  border: none;
  border-bottom: 1px solid #dfe4e7;
  margin: 8px 0;
}

.button.user-menu__button {
  background: transparent;
  border-radius: 0;
  font-weight: 400;
  text-align: left;
}
.button.user-menu__button:not(:disabled):hover {
  background: #f5f7f7;
}
.button.user-menu__button:not(:disabled):active {
  background: #dfe4e7;
}

.user-menu__button .button__inner {
  justify-content: flex-start;
  padding: 6px 16px;
}

.user-menu__button .button__icon--left {
  margin-right: 12px;
}

.user-option {
  position: relative;
}

.user-option__input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.user-option__label {
  cursor: pointer;
}

.user-option__indicator {
  position: absolute;
  top: 50%;
  right: 0;
  width: 18px;
  height: 18px;
  margin: 0 3px;
  background: #ffffff;
  border: 2px solid #355365;
  border-radius: 50%;
  transform: translateY(-50%);
}
.user-option__input:checked ~ .user-option__label .user-option__indicator {
  background: #003832;
  border-color: #003832;
}

.user-option__icon {
  opacity: 0;
  flex: 0 0 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  font-size: 22px;
  transform: translate(-50%, -50%);
}
.user-option__input:checked ~ .user-option__label .user-option__icon {
  opacity: 1;
}

.user-panel {
  max-width: 300px;
}

.user-panel .tooltip__content {
  padding: 8px 0;
}

.tooltip.user-panel {
  max-width: 240px;
  width: 100%;
}

.user-panel__chevron {
  flex: 0 0 24px;
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 24px;
  transform: translateY(-50%);
  transition: transform 150ms ease-out;
}
.user-details__description + .user-panel__chevron {
  top: auto;
  bottom: 0;
  transform: none;
}
.user-panel__trigger.is-open .user-panel__chevron {
  transform: translateY(-50%) rotate(180deg);
}
.user-panel__trigger.is-open .user-details__description + .user-panel__chevron {
  transform: rotate(180deg);
}

.user-panel__trigger {
  padding: 0;
  text-align: left;
}

.user-picker {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.user-picker__item {
  display: block;
  flex: 0 0 100%;
  width: 100%;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  padding-bottom: 8px;
}

.add-organization__footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.checkbox-form__group {
  margin-bottom: 24px;
}

.create-building__footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.create-project__footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.edit-organization-logo__footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.form {
  max-width: 700px;
}
.form + .form {
  margin-top: 48px;
}

.form__button {
  margin-top: 48px;
}

.form__error {
  color: #de350b;
}

.login {
  padding-bottom: 80px;
  overflow: hidden;
}
@media only screen and (max-width: 799px) {
  .container > .login {
    margin-right: -24px;
    margin-left: -24px;
  }
}

.login__logo {
  margin: 0 auto;
}

.login__card-wrapper {
  position: relative;
  z-index: 2;
}

.login__image {
  position: absolute;
  z-index: -1;
  pointer-events: none;
}

.login__image--left {
  bottom: -10px;
  left: -230px;
}

.login__image--right {
  bottom: -10px;
  right: -230px;
  transform: scaleX(-1);
}

.login__language-buttons {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.organization-logo__title {
  margin-bottom: 1em;
}

.organization-logo__image {
  width: 100px;
  height: 100px;
}

.organization-logo__buttons {
  display: flex;
  gap: 8px;
}

.page-title {
  margin-bottom: 16px;
}
.page-title.page-title--main {
  position: sticky;
  top: 0;
  margin-bottom: 28px;
  min-height: 54px;
  background: #ffffff;
  z-index: 5;
  padding: 4px 0;
  margin-top: -4px;
}

.page-title__title {
  display: inline-block;
  word-break: break-word;
}

.page-title__indicator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.page-title__icon {
  flex-shrink: 0;
}
.page-title__icon--saved {
  color: #05a763;
}
.page-title__icon--error {
  color: #ff003d;
}

.project-card {
  display: block;
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
}
.project-card.card--with-link {
  transition: background 150ms ease-in-out, box-shadow 150ms ease-in-out;
}
.project-card.card--with-link:hover {
  background: #ffffff;
  box-shadow: 0 8px 12px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
}

.project-card__tags {
  margin-top: 16px;
}

.project-card__tags--in-table {
  margin-top: 0;
  margin-right: 8px;
}

.project-card__stat {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
@media only screen and (min-width: 600px) {
  .project-card__stats--bottom .project-card__stat:nth-child(odd) {
    flex-basis: 34%;
    max-width: 34%;
  }
}
@media only screen and (min-width: 600px) {
  .project-card__stats--bottom .project-card__stat:nth-child(even) {
    flex-basis: 66%;
    max-width: 66%;
  }
}

.project-card__stats {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.project-card__stats--bottom {
  margin-top: 16px;
}
@media only screen and (min-width: 600px) {
  .project-card__stats--bottom {
    flex-direction: row;
  }
}

.project-card__top {
  padding-bottom: 16px;
  border-bottom: 1px solid #dfe4e7;
}

.project-card__bottom {
  padding-top: 16px;
  font-size: 13px;
}

.project-card__description {
  margin-top: 4px;
  color: #355365;
}

.project-card .card__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.project-card__key,
.project-card__tags-title {
  margin-bottom: 4px;
  color: #5d7483;
  font-size: 12px;
  line-height: 16px;
}

.project-card__value.is-inactive {
  color: #899aa5;
  font-weight: 400;
}

.project-meta__footer {
  display: flex;
  gap: 12px;
}

.project-version-form__select {
  min-width: 240px;
}

.project-version-form__footer {
  display: flex;
  gap: 12px;
}

.app__project-part-toggle {
  margin: 4px 16px 4px 0;
}

.app__party-error-icon {
  font-size: 16px;
  color: #de350b;
}

.app__party-row--invalid {
  color: #de350b;
}

.kb-preview__menu {
  position: absolute;
  left: 0;
}

.kb-block {
  margin-bottom: 1em;
}
.kb-block--no-margin {
  margin-bottom: 0;
}

.kb-list {
  list-style: square;
  padding-left: 40px;
}

.kb-image {
  max-height: 1000px;
  max-width: 1000px;
  margin-bottom: 1em;
}

.kb-video {
  max-width: 560px;
  margin-bottom: 1em;
}

.kb-video__inner {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
}

.kb-video__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 315px;
  max-width: 560px;
}

.editor {
  display: grid;
  grid: min-content auto/1fr;
  height: 100vh;
}

.editor--two-sided {
  grid: min-content auto/1fr 1fr;
}

.editor__list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.editor__list--padded {
  padding: 8px;
}

.editor__indented {
  padding-left: 16px;
}

.editor__toggle-link {
  cursor: pointer;
}

.editor-side {
  position: relative;
  width: 100%;
  overflow-y: auto;
}

.editor-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eef1f4;
  padding: 8px;
}

.editor-tabs__tabs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.editor-tabs__tab--invalid {
  color: #de350b;
}

.editor-tabs__actions {
  display: flex;
  gap: 8px;
}

.editor-padded {
  padding: 16px;
  padding-bottom: 80px;
}

.editor-output {
  width: 100%;
  height: calc(100% - 52px);
  margin-bottom: -10px;
}

.editor-conf {
  padding-bottom: 72px;
}

.editor-node {
  flex-grow: 1;
  border: 1px solid hsl(217, 11%, 82%);
  border-radius: 4px;
}

.editor-node__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #eef1f4;
  padding: 3px 5px;
}
.editor-node--invalid > .editor-node__title {
  background: #ffebe6;
}

.editor-node__title--clickable {
  cursor: pointer;
}

.editor-node__title-details {
  display: flex;
  gap: 4px;
}

.editor-node__type {
  background: #667080;
  color: #eef1f4;
  font-size: 80%;
  line-height: 14px;
  padding: 3px 5px;
  border-radius: 4px;
}

.editor-node__id {
  background: hsl(217, 11%, 82%);
  color: #667080;
  font-size: 80%;
  line-height: 14px;
  padding: 3px 5px;
  border-radius: 4px;
}

.editor-node__value {
  color: hsl(100, 30%, 50%);
  font-weight: 700;
}

.editor-node__details {
  border-top: 1px solid hsl(217, 11%, 82%);
  padding: 4px;
  padding-left: 16px;
}

.editor-node__error {
  color: #de350b;
}

.editor-list__items {
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.editor-list__item {
  background: rgba(238, 241, 244, 0.25);
  border: 1px solid #ececec;
  border-radius: 12px;
  padding: 22px;
}

.editor-list__id {
  padding-bottom: 16px;
}

.editor-list__add-container {
  align-self: center;
}

.editor-list__remove-container {
  padding-top: 16px;
}

.editor__modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.editor-json {
  white-space: pre-wrap;
  font-family: monospace;
  padding: 8px;
  padding-bottom: 72px;
}

.editor-form-obj {
  background: rgba(238, 241, 244, 0.25);
  border: 1px solid #ececec;
  border-radius: 12px;
  padding: 22px;
}

.editor__user {
  position: fixed;
  bottom: 0;
  z-index: 1;
  background: white;
  border-top-right-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  padding: 8px;
}

.editor__actions {
  display: flex;
  gap: 8px;
}

.editor__multiline {
  white-space: pre-wrap;
}

.editor__warning {
  color: #db8400;
}

.editor__error {
  color: #de350b;
}

.editor__add-node-button {
  align-self: flex-start;
}

.editor__pagination {
  display: flex;
  align-items: center;
  gap: 8px;
}

.editor__pagination-label {
  min-width: 100px;
  text-align: center;
}

.editor__cci-filter {
  max-width: 320px;
}

.editor__cci-additional-row {
  opacity: 0.5;
}

.table__footer-actions {
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
  margin-top: 10px;
}

.editor__translated-text-input .textarea__input {
  min-height: auto;
  height: 56px;
}